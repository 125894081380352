"use client";

import { Button } from "@/components/shared/ui/button";
import { Card, CardContent, CardHeader } from "@/components/shared/ui/card";
import { ContactInfoCard } from "@/components/shared/ui/contact-info-card";
import { Separator } from "@/components/shared/ui/separator";
import { ShopCustomer } from "@/lib/customers/customer-types";
import { useToast } from "@/state-management/hooks/use-toast";
import { Heart } from "lucide-react";
import Link from "next/link";
import { useState } from "react";
import { updateCustomerFavorite } from "../customer-select/save-favorite-action";

type CustomerInfoProps = {
    customer: ShopCustomer;
    deliveryDates?: string[];
};

export default function CustomerInfo({ customer, deliveryDates }: CustomerInfoProps) {
    const { toast } = useToast();
    const [isFavorite, setIsFavorite] = useState<boolean>(customer.isFavorite);

    const handleFavoriteClick = async (customerId: number, isFavorite: boolean) => {
        try {
            setIsFavorite(isFavorite);
            const result = await updateCustomerFavorite(customerId, isFavorite);

            if (!result.success) {
                setIsFavorite(!isFavorite);
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: "Failed to update favorite status"
                });
            } else {
                toast({
                    variant: "default",
                    title: "Success",
                    description: "Favorite customers updated"
                });
            }
        } catch (error) {
            setIsFavorite(!isFavorite);
            toast({
                variant: "destructive",
                title: "Error",
                description: "Failed to update favorite status"
            });
        }
    };

    return (
        <Card className="mb-8 overflow-hidden">
            {customer.isOnHold ? (
                <CardHeader
                    className={`flex w-full flex-row items-center justify-between space-y-0 bg-red-300 p-2`}
                >
                    <div className=" flex w-full flex-col items-center justify-between">
                        <div className="h-full w-full text-center text-xl font-bold underline">
                            Your account is currently on credit hold. Please contact your KSS
                            Collection Agent.
                        </div>

                        <div className="flex w-full flex-row items-center justify-evenly">
                            <Link href="/ar-statement">
                                <Button>View AR Statement</Button>
                            </Link>
                            {customer.collectionAgentContactInfo && (
                                <ContactInfoCard
                                    contactInfo={customer.collectionAgentContactInfo}
                                />
                            )}
                        </div>
                    </div>
                </CardHeader>
            ) : null}
            <CardContent>
                <div className="flex flex-row justify-around gap-8 pt-4">
                    <div className="text-baseline flex flex-row text-3xl font-bold">
                        <Button
                            variant="ghost"
                            onClick={() => handleFavoriteClick(customer.customerID, !isFavorite)}
                            aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
                        >
                            <Heart
                                className={`!h-10 !w-10 ${
                                    isFavorite ? "fill-red-500 text-red-500" : "text-gray-400"
                                }`}
                            />
                        </Button>
                        <p className="mt-2">
                            <span className="mr-2 text-lg font-semibold text-gray-600">
                                ({customer.customerID})
                            </span>
                            {customer.customerName}
                        </p>
                    </div>
                    <div className="space-y-1">
                        <p className="text-sm font-medium text-gray-500">License Number</p>
                        <p className="text-base">{customer.licenseNumber}</p>
                    </div>

                    <div className="space-y-1">
                        <p className="text-sm font-medium text-gray-500">State</p>
                        <p className="text-base">{customer.state}</p>
                    </div>

                    <div className="space-y-1">
                        <p className="text-sm font-medium text-gray-500">Address</p>
                        <p className="text-base">{customer.address}</p>
                    </div>

                    {customer.pendingCartQuantity !== undefined &&
                        customer.pendingCartQuantity > 0 && (
                            <div className="rounded-lg bg-blue-50 p-4">
                                <p className="font-medium text-blue-700">
                                    {customer.pendingCartQuantity} items in pending cart
                                </p>
                            </div>
                        )}
                </div>

                {deliveryDates && deliveryDates.length > 0 && (
                    <>
                        <Separator className="my-4" />
                        <div className="space-y-3">
                            <h3 className="font-semibold text-gray-900">Upcoming Deliveries</h3>
                            <div className="grid grid-cols-2 gap-2">
                                {deliveryDates.map((date, index) => (
                                    <div
                                        key={index}
                                        className="rounded-md bg-gray-50 p-2 text-sm text-gray-700"
                                    >
                                        {new Date(date).toLocaleDateString("en-US", {
                                            weekday: "short",
                                            month: "short",
                                            day: "numeric"
                                        })}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </CardContent>
        </Card>
    );
}
