import { RepContactInfo } from "@/lib/nav/nav-types";
import { UserIcon } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";

export function ContactInfoCard({
    contactInfo,
    className
}: {
    contactInfo: RepContactInfo;
    className?: string;
}) {
    const large = !contactInfo.supplier;

    return (
        <div key={contactInfo.supplier} className={`flex flex-col justify-start ${className}`}>
            <div className="flex flex-row items-center justify-start gap-2 align-middle">
                <Avatar className="">
                    <AvatarImage src={contactInfo.photoUrl} />
                    <AvatarFallback>
                        <UserIcon className="h-5 w-5" />
                    </AvatarFallback>
                </Avatar>
                <div className="flex flex-col font-medium">
                    <span className={` ${large ? "text-md" : "text-sm"}`}>{contactInfo.name}</span>

                    <a
                        className={`${large ? "text-md" : "text-sm"} text-blue-600 underline`}
                        href={`mailto:${contactInfo.email}`}
                    >
                        {contactInfo.email.toLowerCase()}
                    </a>
                </div>
            </div>
            {contactInfo.supplier && (
                <ul className=" list-disc pl-12 pt-2 text-sm text-gray-600">
                    {contactInfo.supplier.split("<br/>").map((supplier) => (
                        <li key={supplier}>{supplier}</li>
                    ))}
                </ul>
            )}
        </div>
    );
}
